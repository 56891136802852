.price{
    width: 80%;
    margin: auto;
}

.pr__bx{
    box-shadow: 0px 0px 8.1px 0px #00000075;
background-color: #fff;
}
.pr_row{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding: 50px;
}
.pr_col{
    flex-basis: 49%;
}
.pr_col h2{
    font-family: Inter;
font-size: 34px;
font-weight: 700;
line-height: 44px;
letter-spacing: -0.6399999856948853px;
text-align: left;
color: black;
}
.pr_col p{
    
font-family: Inter;
font-size: 24px;
font-weight: 400;
line-height: 38px;
letter-spacing: -0.4699999988079071px;
text-align: left;
color: #3D3D3D;

}
.pr_col button{
    width: Hug (160px)px;
height: Hug (56px)px;
padding: 5px 40px 5px 40px;
gap: 10px;
border-radius: 8px;
font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 46px;
letter-spacing: -0.6800000071525574px;
text-align: center;
background: #4484FC;
color: #fff;

}
@media (max-width:850px) {
    .pr_col h2{
        text-align: center;
    }
    .pr_col p{
        text-align: center;
    }
}
