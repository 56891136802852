.contact__us{
    background: #D9D9D9;

}
.con__div{
    margin: auto;
    width: 80%;
}
.con__row{
    display: flex;
    justify-content: space-between;
   
}
.cont__col{
    flex-basis: 20%;
    background: #1F3584;
    padding: 40px;

}
.cont__colb{
flex-basis: 77%;
padding: 40px;
}
.cont__col h2{
    font-family: Poppins;
font-size: 28px;
font-weight: 600;
line-height: 42px;
text-align: left;
color: #fff;
}
.cont__col p{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: left;
color: #fff;
}

.con__coll{
    flex-basis: 49%;
}
.con__coll label,#btt{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
color: black;    
}
.con__coll input,.cont__colb input{
    width: 100%;
}
.cont__colb button{
    background: #1F3584;
    width: Fixed (249px)px;
    height: Fixed (52px)px;
    top: 1846px;
    left: 1436px;
    padding: 15px 48px 15px 48px;
    gap: 10px;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
color: #fff;        
}

@media (max-width:850px) {
    .con__row{
        flex-direction: column;
    }
}