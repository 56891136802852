.service{
    width: 80%;
    margin: auto;
}
.ser_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sec_cola{
    flex-basis: 30%;
}
.sec_colb{
    flex-basis: 65%;
}

.sec_cola img{
    width: 100%;
}

.sec_colb h2{
    font-family: Lato;
font-size: 40px;
font-weight: 700;
line-height: 60px;
text-align: left;
color: black;
}
.sec_colb p{
    font-family: Lato;
font-size: 20px;
font-weight: 400;
line-height: 34px;
text-align: left;
color: #6B6B6B;

}

.serv2{
    width: 80%;
    margin: auto;
    background: #1F3584;
   padding: 50px;
}
.serv2_row{
    display: flex;
    justify-content: space-between;

}
.serv2_col{
    flex-basis: 33%;
    background: #fff;
  padding: 20px;

border-radius: 10px;


}

.serv2 h2{
    font-family: Plus Jakarta Sans;
font-size: 54px;
font-weight: 800;
line-height: 56px;
text-align: center;
color: #fff;
}
.serv2 p{
    font-family: Lato;
font-size: 20px;
font-weight: 400;
line-height: 34px;
text-align: center;
color: #fff;
}

.serv2_col h2{
    font-family: Inter;
font-size: 28px;
font-weight: 700;
line-height: 44px;
letter-spacing: -0.6399999856948853px;
text-align: left;
color: #3D3D3D;

} 
.serv2_col p{
    font-family: Inter;
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.4699999988079071px;
text-align: left;
color: #3D3D3D;
}
@media (max-width:850px) {
    .ser_row,.serv2_row{
        flex-direction: column;
    }
    .sec_colb{
        margin-top: 20px;
    }
    .sec_colb h2,.sec_colb p{
        text-align: center;
    }
}