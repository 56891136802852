@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
    margin: 0;
    padding: 0;
}
body{
    
    overflow-X: hidden;
}
html {
    overflow-x: hidden;
  }
  
  body {
    position: relative;
    overflow-x: hidden;

  }
.header_top{
    height: 115vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.9), rgba(15, 14, 14, 0.7)),url(./Hero.jpg);

    background-position:center ;
    background-size: cover;
    opacity: 1;


}
.container_top{
width: 80%;
margin: auto;
text-align: center;
}

.container_top h4{

font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: center;
padding-top: 100px;
color: #9EA3B5;

}
.container_top p{
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #9EA3B5;
    
}
.container_top h1{
    color: #ffff;
    font-family: Plus Jakarta Sans;
    font-size: 55px;
    font-weight: 800;
    line-height: 66px;
    text-align: center;
    
}
.container_top h3{
color: #15B2F5;

font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 400;
line-height: 32px;
text-align: center;

}
.container_top button{
    width: Hug (233px)px;
height: Hug (64px)px;
padding: 12px ;
gap: 0px;
border-radius: 60px ;
background: #15B2F5;
font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 500;
line-height: 32px;
text-align: center;
color: #fff;

}
.row__mini,.row__min2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col__mini{
   flex-basis: 24%; 
}
.col__mini2{
    flex-basis: 46%;
}

.col__mini2 p{

font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: #ffff;
}

.sec2{
    width: 80%;
    margin: auto;
}
.row__sec2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.col__sec2{
    flex-basis: 49%;
}
.col__sec2 h2{

font-family: Plus Jakarta Sans;
font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: left;
color: black;
}
.col__sec2 p{

    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    
color: #545971;

}
.col__sec2 h4{
    color: #1F3584;

font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;

}
.rond{
    width: 400px;
height: Hug (80px)px;
padding: 12px 32px 12px 32px;
gap: 4px;
border-radius: 24px ;
background: #15B2F5;
color: #fff;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: center;

}
.col__sec2 img{
    width: 100%;
}
.sec3{
    width: 80%;
    margin: auto;
}

.sec3 h2{
    font-family: Plus Jakarta Sans;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    color: black;
    
}
.sec3 p{
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    
color: #545971;
}
.row__sec3{
    display: flex;
    justify-content: space-between;
    
}

.col__sec3{
    flex-basis: 32%;
}
.col__sec3 img{

}
.col__sec3 h3{

font-family: Plus Jakarta Sans;
font-size: 22px;
font-weight: 700;
line-height: 32px;
text-align: left;
color: black;
margin-top: 8px;
margin-bottom: 6px;
}
.col__sec3 p{

font-family: Plus Jakarta Sans;
font-size: 16px;
font-weight: 400;
line-height: 25px;
text-align: left;
color: #545971;

}
#boxx{
padding: 32px ;
gap: 32px;
border-radius: 24px;
background: #15B2F5;
text-align: center;
}
#boxx h2{
font-family: Plus Jakarta Sans;
font-size: 24px;
font-weight: 700;
line-height: 32px;
text-align: center;
color: #fff;
}
#boxx p{

font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 400;
line-height: 28px;
text-align: center;
color: #fff;
}
#boxx button{
    width: Hug (233px)px;
    height: Hug (64px)px;
    padding: 12px ;
    gap: 0px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 60px ;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color:#15B2F5 ;
}
.section4{
    height: 110vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.1), rgba(15, 14, 14, 0.7)),url(./Content-min.png);

    background-position:center ;
    background-size: cover;


}

.section4__div{
width: 80%;
margin: auto;

}

.sec4__row{
    display: flex;
    justify-content: space-between;
    
    align-items: center;
}
.sec4__col{
flex-basis: 48%;

}

.sec4a__row{
    display: flex;
    justify-content: space-between;
   
}
.sec4a__col{
    flex-basis: 20%;
}
.sec4a__col img{
    width: 60%;
}
.sec4a__colb{
    flex-basis: 79%;
}
.sec4a__col{

}
.sec4__col h2{
color: #fff;
font-family: Plus Jakarta Sans;
font-size: 40px;
font-weight: 700;
line-height: 64px;
text-align: left;

}
.sec4a__colb h3{

font-family: Plus Jakarta Sans;
font-size: 22px;
font-weight: 700;
line-height: 32px;
text-align: left;
color: #fff;
}
.sec4a__colb p{
color: #545971;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: left;

}


.sec5__div{
    width: 80%;
    margin: auto;
}
.sec5__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sec5__col{
    flex-basis: 48%;
}
.sec5__col img{
    width: 100%;
}
.sec5__col h2{
color: black;
font-family: Plus Jakarta Sans;
font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: left;

}
.row__sec5b{
    display: flex;
    justify-content: space-between;
}
.col__sec5bb{
    flex-basis: 25%;
}
.col__sec5bb h3{
color: black;
font-family: Plus Jakarta Sans;
font-size: 24px;
font-weight: 700;
line-height: 32px;
text-align: left;

}
.col__sec5b{
    flex-basis: 84%;
}
.col__sec5b p{
color:#545971;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    
}
.sec6{
    margin: auto;
    width: 80%;
}
.sec6__row{
    display: flex;
    justify-content: space-between;
}
.sec6__col{
    flex-basis: 33%;
}
.sec6 h2{
color: black;
font-family: Plus Jakarta Sans;
font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: center;

}
.sec6 p{
color: #545971;
font-family: Plus Jakarta Sans;
font-size: 24px;
font-weight: 400;
line-height: 36px;
text-align: center;

}
.sec6__col img{
    width: 100%;
}
.sec6__col p{
color: #545971;
font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 400;
line-height: 28px;
text-align: left;

}
.sec6__col h3{
color: #000;
font-family: Plus Jakarta Sans;
font-size: 32px;
font-weight: 700;
line-height: 40px;
text-align: left;

}
.sec6 h4{
color: #15B2F5;
font-family: Plus Jakarta Sans;
font-size: 24px;
font-weight: 700;
line-height: 32px;
text-align: center;

}

.sec7{
    width: 80%;
    margin: auto;
}
.sec7__row{
    display: flex;
    justify-content: space-between;
    
}
.sec7__col{
    flex-basis: 48%;
}
.sec7__col h2{
color: #000;
font-family: Plus Jakarta Sans;
font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: left;

}
.sec7__col p{
color: #000;
font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 400;
line-height: 32px;
text-align: left;

}

.faq__sec{
    width: 80%;
    margin: auto;
}
details{
    background: #F3F5F9;
padding: 10px;
}
.faq__sec h2{
    font-family: Nunito;
font-size: 36px;
font-weight: 700;
line-height: 49.1px;
text-align: center;
color: aqua;
}
.faq__sec p{
    font-family: Nunito;
font-size: 18px;
font-weight: 300;
line-height: 24.55px;
text-align: left;
color:#9EA3B5;
}
.sec8{
    height: 80vh;
    background-image: linear-gradient(rgba(15, 14, 14, 0.1), rgba(15, 14, 14, 0.1)),url(./Banner-min.png);

    background-position:center ;
    background-size: cover;
    opacity: 1;
   
}

.sec8__div{
    margin: auto;
    width: 80%;
}
.sec8__div h2{

font-family: Plus Jakarta Sans;
font-size: 47px;
font-weight: 800;
line-height: 56px;
text-align: left;
color: #fff;
padding-top: 80px;
}
.sec8__div p{
color: #15B2F5;
font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 400;
line-height: 32px;
text-align: left;

}
.sec8__div button{
    width: Hug (233px)px;
    height: Hug (64px)px;
    padding: 8px ;
    gap: 0px;
    border-radius: 60px ;
    background: #15B2F5;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
color: #fff;
padding: 10px;    
}
.rhome{
    display: flex;
    justify-content: space-between;
}
.col_h{
    flex-basis: 48%;
}
.col_h h3{

font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;
color: #1F3584;

}

@media (max-width:850px) {
    .row__sec2,.rhome,.row__sec3,.sec4__row,.sec4a__row,.sec5__row,.row__sec5b,.sec6__row,.sec7__row,.row__min2,.row__mini{
        flex-direction: column;
    }
    .col__sec3,.col__sec3 h3,.col__sec3 p{
        text-align: center;
    }
    .sec4a__col img{
        width: 10%;

    }
    .sec4a__col,.sec4a__colb h3,.sec4a__colb p{
        text-align: center;
    }
    .section4{
        height: 150vh;
        background-image: linear-gradient(rgba(15, 14, 14, 0.1), rgba(15, 14, 14, 0.7)),url(./Content-min.png);
    
        background-position:center ;
        background-size: cover;
    
    
    }
    .header_top{
        height: 120vh;
    }    
    .col__mini2 p{
        text-align: center;
    }
}
