.abt__div{
    width: 80%;
    margin: auto;
}

.abt_row{
    display: flex;
    justify-content: space-between;
}
.abt_cola{
    flex-basis: 30%;
}
.abt_cola img{
    width: 100%;
}
.abt_colb{
    flex-basis: 65%;
}
.abt_colb h2{
    font-family: Lato;
font-size: 40px;
font-weight: 700;
line-height: 60px;
text-align: left;
color: black;

}
.abt_colb p{
    font-family: Lato;
font-size: 20px;
font-weight: 400;
line-height: 34px;
text-align: left;

color: #6B6B6B;

}
.abt_col2{
    flex-basis: 24%;
}
.abt__div2{
    width: 80%;
    margin: auto;
    background: #1F3584;
    padding: 50px;


}
.abt__div2 h2{
    font-family: Lato;
font-size: 40px;
font-weight: 700;
line-height: 60px;
text-align: left;
color: #FFFFFF;

}
.abt_col2 h2{
    font-family: Lato;
font-size: 20px;
font-weight: 600;
line-height: 34px;
text-align: left;
color: #FFFFFF;
}
.abt_col2 p{
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    
color: #6B6B6B;
}
#rght{
    text-align: right;
}

@media (max-width:850px) {
    .abt_row,.pr_row{
        flex-direction: column;
    }
   
    #rght{
        margin-top: 10px;
        text-align:center;
    }
}